export const fields = [
    { key: 'index', label: "No", _style:'min-width:30px'},
    { key: 'select', label: "", _style:'min-width:30px' },
    { key: 'kecamatan', label: "Kecamatan", _style:'min-width:100px;' },
    { key: 'desa', label: "Desa", _style:'min-width:100px;' },
    { key: 'luas_ha', label: "Luas Ha", _style:'min-width:100px;' },
    { key: 'stts_lhn', label: "Status", _style:'min-width:100px;' },
    // { key: 'fid', label: "FID", _style:'min-width:30px' },
    // { key: 'objectid', label: "OBJECTID", _style:'min-width:30px' },
    // { key: 'nama_kabup', label: "Kabupaten", _style:'min-width:100px' },
    // { key: "nama_prov", label: "Provinsi", _style:'min-width:50px'},
    { key: 'nma_poktan',label: "Nama Poktan", _style:'min-width:100px;' },
    { key: 'id_poktan', label: "ID Poktan", _style:'min-width:100px;' },
    { key: 'ka_poktan', label: "KA. Poktan", _style:'min-width:100px;' },
    { key: 'nik_ka_pok', label: "NIK KA. Poktan", _style:'min-width:100px;' },
    { key: 'alamat_ka', label: "Alamat KA", _style:'min-width:100px;' },
    { key: 'kndisi_lhn', label: "Kondisi Lahan", _style:'min-width:100px;' },
    { key: 'pol_tnm', label: "Pola Tanam", _style:'min-width:100px;' },
    { key: 'jns_pngair', label: "Jenis Pengairan", _style:'min-width:100px;' },

    // { key: 'shape_leng', label: "Shape Length", _style:'min-width:100px;' },
    // { key: 'shape_area', label: "Shape Area", _style:'min-width:100px;' },
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:100px;' },
    { key: 'updated_at', label: "Tanggal Diubah", _style:'min-width:100px;' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
